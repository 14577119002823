import {Component, Vue} from 'vue-property-decorator';

@Component({})
export class DataTableProps extends Vue {
    public tableProps(items: unknown[]): object {
        return {
            items,
            hideDefaultFooter: false,
            singleExpand: true,
            disableSort: true,
            itemKey: 'id',
            showExpand: true,
            footerProps: {
                itemsPerPageOptions: [10, 25, 50, -1],
            },
        };
    }
}
